
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import FormField from './FormField.vue'
import ItemFieldSelector from './ItemFieldSelector.vue'
import { EventBus } from '@/eventBus'

import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import ContainerEditorToolbar from '@/components/Item/LayoutEditor/ContainerEditorToolbar.vue'
import itemFormMethods from '@/components/Item/itemFormMethods'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'
import LayoutEditorImportExport from '@/components/Item/LayoutEditor/LayoutEditorImportExport.vue'
import FieldSetEditorToolbar from '@/components/Item/LayoutEditor/FieldSetEditorToolbar.vue'
import Vue from 'vue'
import { FieldType, LP } from '@/types/LP.types'
import CustomAppComponent from '@/customer-apps/CustomAppComponent.vue'

// @ts-ignore temp, until switching to Vue 3 and Vuex 4
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const menuBarHeightWithFormTopMargin = 40 + 4

export default {
  name: 'ItemForm',

  components: {
    CustomAppComponent,
    FieldSetEditorToolbar,
    LayoutEditorImportExport,
    Item: () => import('@/views/Item.vue'), // For Item > ItemForm > Item to be possible
    ContainerEditorToolbar,
    FormField,
    ItemFieldSelector,
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [String, Number],
      default: null,
    },
    itemMeta: {
      type: Object,
      default: () => {},
    },
    targetResource: {
      type: String,
      default: '',
    },
    targetId: {
      type: [Number, String],
      default: '',
    },
    targetField: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    itemFormPosition: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      debug: false,
      render: true,
      savingItemLayout: false,
      resettingLayout: false,
      layoutProfilesItemsLoaded: false,
      containerSortable: null,
      fieldSetSortables: [],
      fieldSortables: [],
      fieldSelectorSortables: [],
      itemCopy: {}, // To keep track if item data has been changed
      showLayoutImportExportForm: false,
      itemModalProps: {
        show: false,
        id: null,
        edit: true,
        targetResource: '',
        targetId: '',
        targetField: '',
        resource: '',
        showLoader: false,
        layoutProfileContext: '',
        saveCallback: () => {},
        closeCallback: () => {},
      },
    }
  },

  mounted () {
    EventBus.$on?.('callUpdateMainItemOnHasManyChildItemEventAction', this.updateMainItemOnHasManyChildItemEventAction)
  },

  beforeDestroy () {
    EventBus.$off?.('callUpdateMainItemOnHasManyChildItemEventAction', this.updateMainItemOnHasManyChildItemEventAction)
  },

  computed: {
    ...mapFields(Object.keys(state)),

    // Aava-Vue-527
    // Hack fix to allow item-picker columns search when opened from modal view
    disabledInModal () {
      return !!(this.itemPickerProps?.objectClass && this.modal && this.itemPickerProps?.selectColumns?.length)
    },

    unfinishedDfcCount () {
      return this.itemTotalUnfinishedDfcRequestsCount()
    },

    showLayoutEditorNotAvailableMessage () {
      return this.itemLayoutEditMode && (!this.$vuetify.breakpoint.mdAndUp || this.splitMode)
    },

    layoutEditorNotAvailableMessage () {
      if (this.splitMode) {
        return this.$i18n.t('aava.messages.exit_split_mode')
      }
      return this.$i18n.t('aava.messages.layout_editor_use_larger_resolution')
    },

    modal () {
      return this.itemFormPosition === 'modal'
    },

    splitProps () {
      return this.$route.params
    },

    containersComputed (): LP.Container[] {
      return this.containers.length > 0
        ? this.containers.filter((container: LP.Container) => {
          return this.itemLayoutEditMode || this.fieldSetsComputed(container).length > 0
        })
        : this.virtualContainers
    },

    itemIsPendingSave () {
      return this.itemCopy && this.edit && this.item['@editable'] && JSON.stringify(this.item) !== JSON.stringify(this.itemCopy)
    },

    isInFirstSplitWindow () {
      return this.itemFormPosition === 'first' && this.splitProps.secondResource
    },

    formWidth () {
      if (this.splitMode !== 'vertical') {
        return this.innerWidth
      }
      const formWidth = this.innerWidth * (100 - this.splitModeListSize) / 100
      return this.isInFirstSplitWindow
        ? this.innerWidth - formWidth
        : formWidth
    },

    initializeTrigger () {
      return this.id + '-' + this.edit // + '-' + this.targetId
    },

    item: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },

    showLoaderComputed: {
      get () {
        return this.showLoader
      },
      set (value) {
        this.$emit('updateShowLoader', value)
      },
    },

    triggerForSavingVirtualContainers () {
      if (!this.itemLayoutEditMode) { return false }
      return this.itemLayoutEditMode + this.selectedItemLayoutProfileId
    },

    showItemFieldSelector () {
      return this.itemLayoutEditMode && !this.hideFieldSelector
    },

    containers (): LP.Container[] {
      return this.layoutContainers[this.selectedItemLayoutProfileId] || []
    },

    fieldSets (): LP.FieldSet[] {
      return this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]?.filter(fieldSet => {
        const fields = this.getFieldsInContainerFieldSet(-1, -1, fieldSet)
        return this.itemLayoutEditMode || fields.length > 0
      }) || []
    },

    layoutProfile () {
      return this.itemLayoutProfilesByModel[this.resource]?.filter(lp => lp.id === this.selectedItemLayoutProfileId)?.[0] || {}
    },

    formFields () {
      return this.fields.filter(field => {
        //  (field.name in this.item || field.name + '_' + this.locale in this.item) &&
        return field.visible
      })
        .sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
    },

    style () {
      let height = (this.innerHeight - menuBarHeightWithFormTopMargin - this.itemToolbarHeight)
      if (this.splitMode === 'horizontal') {
        height = this.horizontalSplitModeHeight
      }
      if (this.suppressToolbar) {
        height += 66
      }
      if (this.modal) { height = height - 100 }
      return {
        height: height + 'px',
        position: 'relative',
        overflowY: 'scroll',
        paddingBottom: this.$vuetify.breakpoint.xsOnly ? '76px !important' : '',
      }
    },

    horizontalSplitModeHeight () {
      const padding = 6
      let height = this.innerHeight / 100 * (100 - this.splitModeListSize) - this.itemToolbarHeight - padding
      // Item is opened in horizontal split screen top part
      if (this.isInFirstSplitWindow) {
        height = this.innerHeight / 100 * this.splitModeListSize - this.itemToolbarHeight - menuBarHeightWithFormTopMargin
      }
      return height
    },

    itemToolbarHeight () {
      return this.$vuetify.breakpoint.xsOnly || this.splitMode === 'horizontal'
        ? 64 // On mobile or horizontal split mode
        : 64 + 36
      // Now showing item tabs behind a menu btn
      // and state label on the first row
      // so height is always the same
      // return !this.suppressToolbar && // Toolbar is not hidden
      //   this.showItemTabsOnSeparateToolbar(this.itemLayoutProfilesByModel[this.resource]) &&
      //   this.splitMode !== 'horizontal'
      //   ? 64
      //   : 64
    },

    fields () {
      if (!this.layoutProfileItemsById[this.selectedItemLayoutProfileId]) {
        return []
      }
      return this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
        .map((field, index) => {
          // Set field index which is used when updating new order after drag-n-drop
          field.fieldIndex = index
          return field
        })
    },

    fieldsByName () {
      const byName = {}
      this.fields.forEach(field => {
        if (field.multi_language) {
          this.availableContentLocales.forEach(availableLocale => {
            byName[field.name + '_' + availableLocale] = field
          })
        } else {
          byName[field.name] = field
        }
      })
      return byName
    },

    fieldsByNameNotLocalized () {
      const byName = {}
      this.fields.forEach(field => {
        byName[field.name] = field
      })
      return byName
    },

    selectedItemLayoutProfileId (): number {
      // When specific layout profile context is defined, find and use it
      // Currently only "dialog" option is used when opening has-many item
      // modal edit form in pre-defined layout
      let contextLayoutProfileId = null
      if (this.modalProps?.layoutProfileContext) {
        const contextLayoutProfiles = (this.$store.state.itemLayoutProfilesByModel[this.modalProps.resource] || [])
          .filter(item => {
            return item.context === this.modalProps.layoutProfileContext
          })
        contextLayoutProfileId = contextLayoutProfiles[0].id
      }
      // For modal view layoutProfileId can be passed as an argument
      return contextLayoutProfileId || this.selectedLayoutProfileIdByModel[this.resource]
    },

    // First a request to change LP is set
    // When all has-many items are saved to the server (through dummy DFC)
    // Then do the actual LP change
    waitingItemLayoutProfileChangeId () {
      return this.waitingItemLayoutProfileChangeByModel[this.resource]
    },

    virtualContainers (): LP.Container[] {
      const containers: LP.Container[] = []
      let lastGroupName: any = null
      // In case of fields are assigned to field set but field sets are deleted
      // have to check that something is shown for the user.
      // If fieldsCountWithoutFieldSet = 0 then show all visible fields in virtual containers
      const fieldsCountWithoutFieldSet = this.fields.filter((field: LP.Item) => {
        return field.visible && !field.layout_container_field_set
      }).length
      this.fields.filter((field: LP.Item) => {
        return field.visible && (!field.layout_container_field_set || fieldsCountWithoutFieldSet === 0)
      })
        .forEach((field: LP.Item) => {
          let isNewContainer = false
          const lastContainerIndex = containers.length === 0 ? 0 : containers.length - 1
          if (field.attribute_group_name) {
            const match = containers.find(c => c.name === field.attribute_group_name)
            if (!match) {
              isNewContainer = true
              lastGroupName = field.attribute_group_name
              field.containerIndex = containers.length
            } else {
              field.containerIndex = match.index
            }
          } else {
            if (field.attribute_group_name !== lastGroupName) {
              isNewContainer = true
              lastGroupName = field.attribute_group_name
            }
            field.containerIndex = lastContainerIndex
          }
          if (isNewContainer) {
            const container: LP.Container = {
              virtual: true,
              id: containers.length + 1,
              index: containers.length,
              name: field.attribute_group_name,
              columns: 12,
              sort_order: containers.length,
              fieldSets: [],
            }
            // Set container names from translation
            this.availableContentLocales.forEach(locale => {
              container['name_' + locale] = this.translationsByLocale[locale]?.[this.resource]?.attribute_groups?.[field.attribute_group_name]
            })
            containers.push(container)
          }
          field.fieldSetIndex = 0
        })
      return containers
    },

    firstFreeTextInputField () {
      const matchFieldTypes: FieldType[] = ['text', 'richtext', 'string', 'search_string', 'quantity', 'price', 'decimal', 'numeric', 'raw']
      const textFields = this.formFields
        .filter(field => matchFieldTypes.includes(field.type) && !field.dynamic)
      return textFields?.[0]
    },

    useCacheForLPData () {
      return this.resource !== 'people'
    },
  },

  watch: {
    waitingItemLayoutProfileChangeId () {
      this.handleWaitingItemLayoutProfileChange()
    },

    selectedItemLayoutProfileId (id, before) {
      if (!before) { return } // Triggered with created ()
      this.showLoaderComputed = true
      this.layoutProfilesItemsLoaded = false
      const itemHasChanged = !((this.item.id === parseInt(this.id)) || (!this.item.id && this.id === 'new'))
      const modelHasChanged = this.resource !== util.objectClassUnderscoredName(this.item['@class'])
      if (itemHasChanged || modelHasChanged) {
        this.clearItemCurrentData()
      }
      this.$nextTick(() => {
        this.getItemLayoutProfileItems({
          id,
          useCache: this.useCacheForLPData,
        }).then(() => {
          this.layoutProfilesItemsLoaded = true
          this.$nextTick(() => {
            if (itemHasChanged || modelHasChanged) {
              this.initializeFormItem()
            } else {
              this.initializeSameItemAfterLayoutProfileChange()
            }
          })
        })
      })
    },

    initializeTrigger () {
      // When LP also changed, showLoaderComputed is set to true before by lp ID watcher
      if (this.showLoaderComputed) { return }
      this.showLoaderComputed = true
      this.clearItemCurrentData()
      this.$nextTick(() => {
        this.initializeFormItem()
      })
    },

    triggerForSavingVirtualContainers (value) {
      if (!value) { return }
      this.saveVirtualContainers().then(() => {
        this.createSortables()
      })
    },

    showLayoutEditorNotAvailableMessage (value) {
      if (value) { return }
      // Designer is now available, create sortables so user can drag fields from the column selector
      this.createSortables()
    }
  },

  created () {
    this.$store.dispatch('getAttributeMetadata', this.resource) // Get into cache, used in DFC check to get field types

    // Important to set loader = true here
    // ItemForm is created again when switching between models and model LPs are not loaded yet
    // and set into selectedLayoutProfileIdByModel
    this.showLoaderComputed = true
    this.clearItemCurrentData()

    // To trigger LP change when item opened from list view
    // when default show/edit item LPs are set for list LP,
    // waitingItemLayoutProfileChangeByModel holds its value
    this.handleWaitingItemLayoutProfileChange()

    this.getItemLayoutProfileItems({
      id: this.selectedItemLayoutProfileId,
      useCache: this.useCacheForLPData,
    }).then(() => {
      this.layoutProfilesItemsLoaded = true
      this.initializeFormItem()
    })
    window.addEventListener('beforeunload', this.confirmWindowCloseForItemPendingSave)
  },

  destroyed () {
    window.removeEventListener('beforeunload', this.confirmWindowCloseForItemPendingSave)
  },

  methods: {
    ...methods,
    ...itemFormMethods,
    ...layoutEditMethods,

    // Change item layout profile when the time is right
    // - save changed has-many items
    // - send action when should, but when DFCs are finished
    handleWaitingItemLayoutProfileChange () {
      const id = this.waitingItemLayoutProfileChangeId
      if (!id) { return }
      this.saveChangedHasManyItemTemporaryObjects()
      this.whenQueueIsEmpty().then(() => {
        Vue.set(this.selectedLayoutProfileIdByModel, this.resource, this.waitingItemLayoutProfileChangeId)
        Vue.set(this.waitingItemLayoutProfileChangeByModel, this.resource, null)
      })
    },

    // Set item modal props, has to be here as there can be multiple forms.
    // Keep this method here as it sets value of itemModalProps which is in the component data block
    showItemModal (props) {
      this.itemModalProps = props
    },
  },
}
