import Vue from 'vue'
import api from './../../store/api'
import util from '../../utilities/sharedUtilities'
import localStorageTtl from '@/utilities/localStorageTtl'
import moment from 'moment'
import { LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'
import { Types } from '@/types/AppTypes'

export default {
  getLayoutProfileFromUri: ({ state, dispatch }) => {
    // TODO - change when will be using Vue router
    const query = window.location.hash.split('?')[1]
    let lpFromUrl = ''
    if (query) {
      query.split('&').forEach(value => {
        if (value.split('=')[0] === '_lp') {
          lpFromUrl = value && value.split('=')[1]
        }
      })
    }
    state.layoutProfileIdFromUri = (lpFromUrl && parseInt(lpFromUrl)) || null
    if (state.layoutProfileIdFromUri) {
      const match = state.layoutProfiles.find(layoutProfile => layoutProfile.id === parseInt(state.layoutProfileIdFromUri))
      if (match) {
        dispatch('setSelectedLayoutProfileData', match)
      }
    }
  },

  hideListField: ({ dispatch }, fieldName: string) => {
    dispatch('saveListFieldVisibility', {
      fieldName,
      visible: false,
    })
  },

  showListField: ({ dispatch }, fieldName: string) => {
    return new Promise(resolve => {
      dispatch('saveListFieldVisibility', {
        fieldName,
        visible: true,
      }).then(() => {
        resolve(true)
      })
    })
  },

  emptyList: ({ commit, state }) => {
    commit('resetListCount')
    commit('resetListData')
    state.rowHeight = state.defaultRowHeight
  },

  // Sets 'visibleColumnsByName' for quick access and with
  // left absolute offset, which is used to align header titles and
  // content cells by using css transform: translateX...
  setVisibleColumnsByNameWithLeftOffset: ({ state }) => {
    let leftOffset = 0
    const columns = {}
    state.layoutProfileItems
      .filter(field => field.visible)
      .forEach(field => {
        field.leftOffset = leftOffset
        columns[field.name] = field
        const fixedWidth = field.userFixedWidth || field.forcedWidth
        if (fixedWidth) {
          leftOffset += parseInt(fixedWidth)
        }
      })
    state.visibleColumnsByName = columns
  },

  resetLayoutProfileAndListData: ({ state, commit }) => {
    commit('resetLayoutProfile')
    commit('resetListCount')
    commit('resetListData')
    state.rowHeight = state.defaultRowHeight
  },

  // Retrieve count values for all Layout Profiles in selected Model
  getLayoutProfilesItemCount: ({ state, dispatch }) => {
    return new Promise(resolve => {
      const promises: Promise<any>[] = []
      state.layoutProfiles.forEach(layoutProfile => {
        if (layoutProfile.timelineConfiguration.showItemCount) {
          promises.push(dispatch('getLayoutProfileItemCount', layoutProfile.id))
        }
      })
      Promise.all(promises).then(function (results) {
        resolve(results)
      })
    })
  },

  toggleLayoutProfileInDashboard: ({ dispatch }, { tab, layoutProfile }) => {
    Vue.set(layoutProfile, 'in_dashboard', !layoutProfile.in_dashboard)
    return api.toggleLayoutProfileInDashboard(layoutProfile.id, tab).then(() => {
      dispatch('loadAndPrepareDashboardContent')
    })
  },

  toggleLayoutProfilePublish: ({ dispatch }, layoutProfile: LP.LayoutProfile) => {
    Vue.set(layoutProfile, 'publish', !layoutProfile.publish)
    return dispatch('saveLayoutProfileData', layoutProfile)
  },

  toggleLayoutProfileGlobalView: ({ state, dispatch }, layoutProfile: LP.LayoutProfile) => {
    Vue.set(layoutProfile, 'global_view', !layoutProfile.global_view)
    return api.sendLayoutProfileGlobalViewOperation(layoutProfile.id, layoutProfile.global_view ? 'add' : 'remove')
  },

  saveLayoutProfileData: ({ dispatch }, layoutProfile: LP.Item) => {
    return new Promise(resolve => {
      return api.updateLayoutProfile(layoutProfile).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Update layout profile data ' + layoutProfile.id })
          .then(foundErrors => {
            if (!foundErrors) { return }
          })
        resolve(response)
      })
    })
  },

  switchLayoutProfileView: ({ state, dispatch }, {
    id,
    view,
    viewOptions,
    forSelectedLayoutProfile,
  }) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, id)
    if (viewOptions) {
      for (const key of Object.keys(viewOptions)) {
        Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
          key, viewOptions[key])
        Vue.set(state.viewOptions, key, viewOptions[key])
      }
    }
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration, 'startView', view)
    if (forSelectedLayoutProfile) {
      // No need for saveLayoutProfileData to not send double request
      // It's called already once when saving layout profile last used timestamp
      return new Promise(resolve => resolve(true))
    } else {
      return dispatch('saveLayoutProfileData', state.layoutProfiles[layoutProfileIndex])
    }
  },

  toggleLayoutProfileShowCount: ({ state, dispatch }, id: number) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, id)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
      'showItemCount', !state.layoutProfiles[layoutProfileIndex]
        .timelineConfiguration.showItemCount
    )
    dispatch('saveLayoutProfileData', state.layoutProfiles[layoutProfileIndex])
    if (state.layoutProfiles[layoutProfileIndex]
      .timelineConfiguration.showItemCount
    ) {
      return dispatch('getLayoutProfileItemCount', id)
    }
    return dispatch('emptyPromise')
  },

  getLayoutProfileItemCount: ({ state }, id: number) => {
    return new Promise(resolve => {
      const layoutProfileIndex = util.findLayoutProfileIndexById(state, id)
      api.getLayoutProfileItemCount(id).then(result => {
        // Check that still exists, user may have switched object class
        if (state.layoutProfiles[layoutProfileIndex]) {
          Vue.set(state.layoutProfiles[layoutProfileIndex], 'count', result.data.count)
        }
        resolve(result.count)
      })
    })
  },

  createNewLayoutProfile: ({ state }, { data, layoutProfile }: { data: any, layoutProfile: LP.LayoutProfile }) => {
    const view = layoutProfile.action
    const resource = layoutProfile.controller
    data.cloneFromId = view === 'index'
      ? state.selectedLayoutProfileId
      : state.selectedLayoutProfileIdByModel[resource]
    return api.createNewLayoutProfile(data)
  },

  updateLayoutProfile: ({ dispatch }, { formData, layoutProfile }) => {
    Vue.set(layoutProfile, 'name', formData.name)
    Vue.set(layoutProfile.timelineConfiguration, 'backgroundColor', formData.timelineConfiguration.backgroundColor)
    return dispatch('saveLayoutProfileData', layoutProfile)
  },

  updateLayoutProfileProp: ({ dispatch }, { layoutProfile, propName, propValue }) => {
    Vue.set(layoutProfile.timelineConfiguration, propName, propValue)
    return dispatch('saveLayoutProfileData', layoutProfile)
  },

  updateLayoutProfileDefaultSort: ({ state, dispatch }, { id, defaultSort, lanesPerRow, userFixedWidths }) => {
    const updateIndex = util.findLayoutProfileIndexById(state, id)
    const layoutProfile = state.layoutProfiles[updateIndex]
    if (defaultSort) {
      layoutProfile.timelineConfiguration.defaultSort = defaultSort
    }
    if (userFixedWidths) {
      layoutProfile.timelineConfiguration.userFixedWidths = userFixedWidths
    }
    if (lanesPerRow && parseInt(lanesPerRow) > 0) {
      layoutProfile.timelineConfiguration.lanesPerRow = parseInt(lanesPerRow)
    }
    Vue.set(state.layoutProfiles[updateIndex], 'timelineConfiguration', layoutProfile.timelineConfiguration)
    return dispatch('saveLayoutProfileData', layoutProfile)
  },

  deleteLayoutProfile: ({ dispatch }, { layoutProfiles, layoutProfileForDelete }) => {
    Vue.delete(layoutProfiles, layoutProfileForDelete.index)
    dispatch('updateLayoutProfileIndexes', layoutProfiles)
    return dispatch('deleteItem', { resource: 'layout_profiles', id: layoutProfileForDelete.id })
  },

  loadLayoutProfileTemplates: ({ state }, view) => {
    Vue.set(state, 'layoutProfileTemplates', [])
    api.loadLayoutProfileTemplates(state.objectClass, view).then((result: AxiosResponse) => {
      Vue.set(state, 'layoutProfileTemplates', (result.data.items ?? []).map((item: Types.Item) => {
        return {
          value: item.id,
          text: item.name,
        }
      }))
    })
  },

  setSelectedLayoutProfileData: ({ commit, dispatch }, layoutProfile: LP.LayoutProfile) => {
    if (!layoutProfile) { return }
    commit('updateValues', {
      selectedLayoutProfile: layoutProfile,
      selectedLayoutProfileId: layoutProfile.id,
      layoutProfileView: (layoutProfile.timelineConfiguration &&
        layoutProfile.timelineConfiguration.startView) || 'list'
    })
    dispatch('applyLayoutProfileDefaultSort', layoutProfile)
  },

  applyLayoutProfileDefaultSort: ({ state }, layoutProfile: LP.LayoutProfile) => {
    const defaultSort = layoutProfile.timelineConfiguration?.defaultSort
    state.sortField = defaultSort
      ? defaultSort.sortField
      : 'created_at'
    state.sortBy = defaultSort
      ? defaultSort.sortBy
      : 'asc'
    state.secondarySortings = defaultSort && defaultSort.secondarySortings
      ? defaultSort.secondarySortings
      : {}
  },

  // Check for each attribute if they have "order" value
  // Old portal had only single sort condition which was stored as "order" value (asc or desc)
  // If no default sort applied before and one attr has sorting condition, use it
  adjustListSortAfterLPItemsLoad: ({ state }, items: LP.Item[]) => {
    if (!items || !items.length) { return }
    // Find and skip if user has already set defaultSort in new way?
    if (state.selectedLayoutProfile?.timelineConfiguration.defaultSort?.sortField) {
      return
    }
    // Find if one attribute has 'order' value
    const itemWithOrder = items.find((item: LP.Item) => !!item.order)
    if (itemWithOrder?.order) {
      state.sortField = itemWithOrder.name
      state.sortBy = itemWithOrder.order
    }
  },

  swapLayoutProfilePosition: ({ state, dispatch }, { index1, index2 }) => {
    const layoutProfiles = state.draggingLayoutProfile.action === 'index'
      ? state.layoutProfiles
      : state.itemLayoutProfilesByModel[state.draggingLayoutProfile.controller]
    const tempField = layoutProfiles[index1]
    Vue.set(layoutProfiles, index1, layoutProfiles[index2])
    Vue.set(layoutProfiles, index2, tempField)
    dispatch('updateLayoutProfileIndexes', layoutProfiles)
  },

  showAllFields: ({ state }) => {
    state.layoutProfileItems.filter(field => !field.visible)
      .forEach(field => {
        Vue.set(state.listFieldsByName[field.name],
          'visible', true)
      })
    return api.showAllFields(state.selectedLayoutProfileId)
  },

  hideAllFields: ({ state }) => {
    state.layoutProfileItems.filter(field => field.visible)
      .forEach(field => {
        Vue.set(state.listFieldsByName[field.name],
          'visible', false)
      })
    Vue.set(state, 'cellPool', [])
    return api.hideAllFields(state.selectedLayoutProfileId)
  },

  sortLayoutProfiles: ({ state }) => {
    const layoutProfiles = state.draggingLayoutProfile.action === 'index'
      ? state.layoutProfiles
      : state.itemLayoutProfilesByModel[state.draggingLayoutProfile.controller]
    return api.sortLayoutProfiles(layoutProfiles)
  },

  getItemLayoutProfileItems: ({ state, dispatch }, {
    id,
    useCache,
  }) => {
    return new Promise(resolve => {
      const fromCache = localStorageTtl.get('lp_data_' + id)
      // From Vuex cache
      if (useCache && state.layoutProfileItemsById[id]) {
        dispatch('updateItemLayoutProfileLastUsedTime', id)
        // TODO just return true when all instances updated with single request for lp_data
        return resolve({
          item: state.layoutProfileItemsById[id],
          containers: state.layoutContainers[id],
          containerFieldSets: state.layoutContainerFieldSets[id],
        })
      } else if (useCache && fromCache) {
        // From local storage cache
        Vue.set(state.layoutProfileItemsById, id, fromCache.items)
        dispatch('updateItemLayoutProfileLastUsedTime', id)
        if ('containers' in fromCache) {
          Vue.set(state.layoutContainers, id, fromCache.containers)
          Vue.set(state.layoutContainerFieldSets, id, fromCache.containerFieldSets)
        }
        resolve(fromCache)
        return
      }
      api.getLayoutProfileItems(id).then((response: AxiosResponse) => {
        if (response.data.items) {
          Vue.set(state.layoutProfileItemsById, id, response.data.items)
          const dataToCache: any = {
            items: response.data.items,
          }
          // Containers and Field Sets are also returned
          // Changed Sep-2022. Now don't have to send separate requests for these
          if ('containers' in response.data) {
            const sortedContainers = (response.data.containers ?? []).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
            Vue.set(state.layoutContainers, id, sortedContainers)
            const sortedFieldSets = (response.data.container_field_sets ?? []).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
            Vue.set(state.layoutContainerFieldSets, id, sortedFieldSets)
            dataToCache.containers = sortedContainers
            dataToCache.containerFieldSets = sortedFieldSets
          }
          // Set to local storage cache for four hours
          localStorageTtl.set('lp_data_' + id, dataToCache, 1000 * 60 * 60 * 4)
          resolve(response.data)
        } else {
          // TODO global error check
          resolve(response.data)
        }
      })
    })
  },

  loadListLayoutProfileItems: ({ state, dispatch }) => {
    const id = state.selectedLayoutProfileId
    const cache = true
    return new Promise(resolve => {
      const fromCache = localStorageTtl.get('lp_data_' + id)
      // If there is cache for the LP, update last_used_at with a separate request here
      // Otherwise requesting layout profile items (below this block) which updates last_used_at by itself
      if (cache && fromCache) {
        dispatch('setLoadedLayoutProfilesData', fromCache)
        dispatch('updateListLayoutProfileLastUsedTime')
        resolve(fromCache)
        return
      }
      api.getLayoutProfileItems(id)
        .then((response: AxiosResponse) => {
          dispatch('globalErrorDisplay', { response, context: 'Get layout profile items ' + id })
          if (response.data.items) {
            // Set to local storage cache for four hours
            localStorageTtl.set('lp_data_' + id, { items: response.data.items }, 1000 * 60 * 60 * 4)
            dispatch('setLoadedLayoutProfilesData', response.data)
            resolve(response.data)
          } else resolve(false)
        })
    })
  },

  updateItemLayoutProfileLastUsedTime: ({ dispatch }, id) => {
    dispatch('saveLayoutProfileData', {
      id,
      last_used_at: moment().format(),
    })
  },

  updateListLayoutProfileLastUsedTime: ({ state, dispatch }) => {
    state.layoutProfiles.forEach(layoutProfile => {
      if (layoutProfile.id === state.selectedLayoutProfileId) {
        layoutProfile.last_used_at = moment().format()
        dispatch('saveLayoutProfileData', layoutProfile)
      }
    })
  },

  setLoadedLayoutProfilesData: ({ dispatch }, data) => {
    dispatch('updateLayoutProfileItems', data.items.map(profileItem => {
      profileItem.forcedWidth = 0
      profileItem.userFixedWidth = profileItem.userFixedWidth || 0
      profileItem.leftOffset = 0
      return profileItem
    }))
    dispatch('storePermanentFilterValues', data.items)
    dispatch('storeUserFixedColumnWidths')
  },

  updateLayoutProfileItems: ({ state, dispatch }, items: LP.Item[]) => {
    Vue.set(state, 'layoutProfileItems', items)
    dispatch('updateLayoutProfileItemsByName')
  },

  updateLayoutProfileItemsByName: ({ state }) => {
    const fieldsByName = {}
    state.layoutProfileItems.forEach(field => {
      fieldsByName[field.name] = field
    })
    Vue.set(state, 'listFieldsByName', fieldsByName)
  },
}
