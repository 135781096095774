import Vue from 'vue'
import api from './../../store/api'
import Raven from 'raven-js'
import { AxiosResponse } from 'axios'
import { LP } from '@/types/LP.types'

export default {
  saveLayoutContainerFieldSet: ({ state, dispatch }, fieldSet: LP.FieldSet) => {
    if (!fieldSet?.layoutContainer?.id) {
      Raven.captureMessage('Trying to save field-set without container', {
        level: 'error',
        extra: {
          fieldSet,
        }
      })
      return new Promise(resolve => resolve(null))
    }
    const selectedLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
    const fieldSets = state.layoutContainerFieldSets[selectedLayoutProfileId]
    const sortOrder = fieldSet?.sort_order && parseInt(fieldSet?.sort_order.toString()) > -1 ? fieldSet.sort_order : (fieldSets.length + 1)
    const payload: any = {
      '@class': 'LayoutContainerFieldSet',
      columns: fieldSet.columns,
      content_columns: fieldSet.content_columns,
      layout_container: {
        '@class': 'LayoutContainer',
        id: fieldSet.layoutContainer.id,
      },
      layout_profile: {
        '@class': 'LayoutProfile',
        id: state.selectedLayoutProfileIdByModel[state.objectClass],
      },
      sort_order: sortOrder,
    }
    state.availableContentLocales.forEach(locale => {
      payload['name_' + locale] = fieldSet['name_' + locale]
    })
    return new Promise(resolve => {
      api.saveLayoutContainerFieldSet(payload).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Save Layout Field-Set' })
        const savedFieldSet = response?.data?.item
        if (!payload.id) {
          Vue.set(fieldSets, fieldSets.length, savedFieldSet)
        }
        resolve(savedFieldSet)
      })
    })
  },

  saveLayoutContainerFieldSetsOrder: ({ state }) => {
    return new Promise(resolve => {
      const itemLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
      const itemLayoutContainerFieldSets = state.layoutContainerFieldSets[itemLayoutProfileId]
      api.sortLayoutProfileChildren(
        itemLayoutProfileId,
        itemLayoutContainerFieldSets,
        'container_field_sets'
      ).then(result => {
        resolve(result)
      })
    })
  },

  updateLayoutContainerFieldSet: ({ state }, fieldSet: LP.FieldSet) => {
    return new Promise(resolve => {
      const payload = {
        '@class': 'LayoutContainerFieldSet',
        id: fieldSet.id,
        columns: fieldSet.columns,
        content_columns: fieldSet.content_columns,
        config: JSON.stringify({
          bgColor: fieldSet.bgColor,
        }),
      }
      state.availableContentLocales.forEach(locale => {
        payload['name_' + locale] = fieldSet['name_' + locale]
      })
      api.saveItem('layout_container_field_sets', payload).then((response: AxiosResponse | null) => {
        resolve(response)
      })
    })
  },

  updateLayoutContainerFieldSetContainer: ({ state }, fieldSet: LP.FieldSet) => {
    return new Promise(resolve => {
      const data = {
        '@class': 'LayoutContainerFieldSet',
        id: fieldSet.id,
        layout_container: {
          '@class': 'LayoutContainer',
          id: fieldSet.layoutContainer?.id,
        },
      }
      api.saveItem('layout_container_field_sets', data).then((response: AxiosResponse | null) => {
        resolve(response)
      })
    })
  },

  deleteLayoutContainerFieldSet: ({ state, dispatch }, fieldSet: LP.FieldSet) => {
    return new Promise(resolve => {
      dispatch('deleteItem', { resource: 'layout_container_field_sets', id: fieldSet.id }).then(deleteOk => {
        // Delete from store
        if (deleteOk) {
          const itemLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
          const fieldSets = state.layoutContainerFieldSets[itemLayoutProfileId] || []
          const deleteIndex = fieldSets.map(c => c.id).indexOf(fieldSet.id)
          Vue.delete(state.layoutContainerFieldSets[itemLayoutProfileId], deleteIndex)
        }
        resolve(deleteOk)
      })
    })
  },
}
