import Vue from 'vue'
import util from '../../../utilities/sharedUtilities'
import listViewAPI from './../../api'
import listFilters from './../../_listFilters'
import listQueries from '@/store/_listQueries'
import clearCache from '@/utilities/clearCache'
import { AxiosResponse } from 'axios'

export default {
  getQueryParameters: ({ commit }) => {
    commit('updateBreakdownSearchIds')
    commit('getAssociationForFromUri')
  },

  deleteBulk: ({ state }, ids: (string | number)[]) => {
    return listViewAPI.deleteBulk(state.objectClass, ids)
  },

  sortLayoutProfileItems: ({ state }) => {
    clearCache('lp_data_' + state.selectedLayoutProfileId)
    return listViewAPI.sortLayoutProfileChildren(
      state.selectedLayoutProfileId,
      state.layoutProfileItems,
      'items',
      'none',
    )
  },

  // TODO - move (related to item form, not list)
  saveItemFieldsOrder: ({ state }, { sortBy = 'orderInDOM' }) => {
    const selectedItemLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
    clearCache('lp_data_' + selectedItemLayoutProfileId)
    return listViewAPI.sortLayoutProfileChildren(
      selectedItemLayoutProfileId,
      state.layoutProfileItemsById[selectedItemLayoutProfileId],
      'items',
      sortBy
    )
  },

  loadFieldUniqueValues: ({ state, dispatch }, fieldName: string) => {
    return new Promise(resolve => {
      listViewAPI.getUniqueValues(state.objectClass, fieldName)
        .then((response: AxiosResponse) => {
          if (response.data.values) {
            state.uniqueValueFilterOptions = response.data.values.sort()
          }
          dispatch('globalErrorDisplay', { response, context: 'field unique values for ' + fieldName })
          resolve(response)
        })
    })
  },

  sendReportExportRequest: ({ dispatch, state }, reportFormat): Promise<AxiosResponse> => {
    return new Promise(resolve => {
      let filters = listFilters.get(0, 0, state)
      if (state.selectedItems.length > 0) {
        filters = [...filters,
          {
            key: 'ids',
            value: state.selectedItems.map(index => state.listItems[index].id)
              .join(',')
          }
        ]
      }
      listViewAPI.sendReportExportRequest(state.objectClass,
        reportFormat,
        filters,
        listQueries.get(state.layoutProfileItems, state.selectedLayoutProfile, {
          forExport: true,
          locale: state.locale,
        })
      )
        .then((response: AxiosResponse) => {
          dispatch('globalErrorDisplay', { response, context: 'Excel export /gorilla/export/' + state.objectClass })
          resolve(response?.data)
        })
    })
  },

  loadObjectStates: ({ state, commit }, className: string) => {
    const filters = {
      _object_type: util.objectClassSingularName(className),
      limit: 1000,
      order: 'name_' + state.locale,
    }
    const queries = ['name', 'summary', 'object_type']
    listViewAPI.fetchListItems('object_states', filters, queries, {})
      .then((response: AxiosResponse) => {
        // Ignore error reporting here, when can get LP items, can most likely get states
        // So not to show double errors
        // dispatch('globalErrorDisplay', { response, context: 'Get object states for ' + className })
        if (response.data.items) {
          commit('updateObjectStates', {
            className,
            states: response.data.items.filter(type => type.object_type === util.objectClassSingularName(className))
          })
        }
      })
  },

  multiEditUpdateValue: ({ state }, { selected, newValues, updatedField }): Promise<AxiosResponse[]> => {
    return new Promise(resolve => {
      if (!selected || !newValues || newValues.length === 0) {
        return resolve([])
      }
      const promises: Promise<AxiosResponse>[] = []
      selected.forEach(selectedIndex => {
        const itemId = state.listItems[selectedIndex].id
        const parameters: any = []
        Object.keys(newValues).forEach(fieldName => {
          const newValue = newValues[fieldName]
          const sendValue = typeof newValue === 'object'
            ? {
                '@class': newValue['@class'],
                id: newValue.id
              }
            : newValue
          parameters.push({
            key: fieldName,
            // Check if type is object but does not have 'id', then set as 'null'
            // API gives error for empty object value
            value: typeof sendValue === 'object' && (sendValue && !sendValue.id)
              ? null
              : sendValue
          })
        })
        promises.push(listViewAPI.updateItemForMultiEdit(state.objectClass, itemId, parameters, updatedField))
      })
      Promise.all(promises).then((results: AxiosResponse[]) => {
        resolve(results)
      })
    })
  },

  removeListFilter: ({ state, dispatch }, fieldName: string) => {
    if (fieldName) {
      Vue.set(state.listFilters, fieldName, '')
      listFilters.setLocalStorageFilter(state.selectedLayoutProfileId, fieldName, '')
    } else {
      return dispatch('emptyPromise')
    }
  },

  setDateFilter: ({ state, commit }, payload) => {
    listFilters.setLocalStorageFilter(state.selectedLayoutProfileId, payload.filterField, payload.filterValue)
    commit('setFilterField', payload)
  },

  clearTemporarySearchFilters: ({ state, dispatch }) => {
    state.focusedFieldFilterValue = ''
    listFilters.clearTemporarySearchFilters(state.selectedLayoutProfileId)
    dispatch('storePermanentFilterValues', state.layoutProfileItems)
  }
}
