import { AxiosResponse } from 'axios'

export default {
  saveListFieldVisibility (layoutProfileItemId, visible) {
    const queries = [{
      key: 'visible',
      value: visible,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  saveListFieldEditableValue (layoutProfileItemId, editable_in_list) {
    const queries = [{
      key: 'editable_in_list',
      value: editable_in_list,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  saveListPermanentFilter (layoutProfileItemId, permanentFilterValue) {
    const queries = [{
      key: 'custom_value',
      value: permanentFilterValue,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  getLayoutProfileItems (layoutProfileId): Promise<AxiosResponse> {
    const queries = []
    const filters: Record<string, string | boolean> = {}
    if (window.location.hash.includes('myProfile')) {
      filters.my_profile = true
    }
    return new Promise(resolve => {
      this.sendRequest('/gorilla/layout_profiles/' + layoutProfileId + '/items', filters, queries).then((response: AxiosResponse) => {
        if (response.data.items) {
          response.data.items = response.data.items.sort((a, b) => {
            return (a.sort_order > b.sort_order) ? 1 : -1
          })
          // Set attributes from field-set config
          response.data.container_field_sets = response.data.container_field_sets?.map(fieldSet => {
            try {
              if (fieldSet.config) {
                const config = JSON.parse(fieldSet.config);
                ['bgColor'].forEach(fieldName => {
                  fieldSet[fieldName] = config[fieldName]
                })
              }
            } catch (e) {
              console.warn('FS not valid JSON', fieldSet.config, fieldSet)
            }
            return fieldSet
          })
        }
        resolve(response)
      })
    })
  },

}
