import clearCache from '@/utilities/clearCache'
import { LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'

export default {
  addNewFieldSet (container = null) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.dialogItemContainerMenu = false
    return new Promise(resolve => {
      this.saveFieldSet({
        layoutContainer: container || this.itemContainerForEdit,
        content_columns: 0,
        columns: 12,
      }).then((response: AxiosResponse) => {
        if (response) {
          this.updateContainersInDOMAndCreateSortings()
        }
        resolve(response)
      })
    })
  },

  updateLayoutContainerFieldSet (fieldSet) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$store.dispatch('updateLayoutContainerFieldSet', fieldSet)
  },

  saveFieldSet (fieldSet) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    return new Promise(resolve => {
      this.$store.dispatch('saveLayoutContainerFieldSet', fieldSet).then(savedFieldSet => {
        resolve(savedFieldSet)
      })
    })
  },

  deleteLayoutContainerFieldSet (fieldSet) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.dialogItemFieldSetMenu = false
    return new Promise(resolve => {
      this.$store.dispatch('deleteLayoutContainerFieldSet', fieldSet).then(result => {
        resolve(result)
      })
    })
  },

  getFieldSetClasses () {
    return [
      'field-set-form',
      (this.itemLayoutEditMode ? ' field-set-edit ' : ''),
      // (this.getFieldSetColsInContainer(container, fieldSet) === 12 ? 'field-set-12' : ''),
      // (this.showLoaderComputed ? ' fieldset-loading-bg ' : ''),
      (this.fieldSets.length > 1 || this.containersComputed.length > 1 ? 'multiple-field-sets' : 'single-field-set'),
    ]
  },

  getFieldSetDefaultDataForContainer (container: LP.Container) {
    return [{
      // name: '',
      content_columns: 0,
      columns: 12,
      layout_container: {
        id: container.id,
      },
    }] as LP.FieldSet[]
  },

  getFieldsInContainerFieldSet (containerIndex, fieldSetIndex, fieldSet) {
    return this.formFields.filter(field => {
      const fieldIsInVirtualContainer = field.containerIndex === containerIndex &&
        field.fieldSetIndex === fieldSetIndex && this.containers.length === 0
      const fieldIsInFieldSet = field.layout_container_field_set &&
        field.layout_container_field_set.id === fieldSet.id
      return fieldIsInVirtualContainer || fieldIsInFieldSet
    })
  },
}
