import Sortable from 'sortablejs'
import Vue from 'vue'
import clearCache from '@/utilities/clearCache'

export default {
  createSortables () {
    if (!this.itemLayoutEditMode) {
      return
    }
    this.$nextTick(() => {
      this.containerSortable = null
      const el = document.getElementById('level-1-sort')
      if (!el) { return }
      this.containerSortable = Sortable.create(el, {
        onEnd: this.onDragEndForContainer,
        chosenClass: 'container-chosen',
        dragClass: 'container-drag',
        handle: '.fa-ellipsis-vertical',
      })
      this.createFieldSetSortables()
      this.createFieldSortables()
      this.createFieldSelectorSortables()
    })
  },

  destroySortables () {
    if (this.containerSortable) {
      this.containerSortable.destroy()
    }
    if (this.fieldSetSortables) {
      this.fieldSetSortables.forEach(sortable => {
        sortable.destroy()
      })
    }
    if (this.fieldSortables) {
      this.fieldSortables.forEach(sortable => {
        sortable.destroy()
      })
    }
    if (this.fieldSelectorSortables) {
      this.fieldSelectorSortables.forEach(sortable => {
        if (!sortable) { return }
        sortable.destroy()
      })
    }
  },

  createFieldSetSortables () {
    this.fieldSetSortables = []
    this.containers.forEach(container => {
      const el = document.getElementById('level-2-sort-' + container.id)
      if (el) {
        this.fieldSetSortables.push(Sortable.create(el, {
          group: 'container',
          onEnd: this.onDragEndForFieldSet,
          chosenClass: 'field-set-chosen',
          dragClass: 'field-set-drag',
          handle: '.fa-ellipsis-vertical',
        }))
      }
    })
  },

  createFieldSortables () {
    this.fieldSortables = []
    this.containers.forEach(container => {
      this.fieldSets.filter(fieldSet => (fieldSet.layout_container && fieldSet.layout_container.id) === container.id)
        .forEach(fieldSet => {
          const el = document.getElementById('level-3-sort-' + fieldSet.id)
          if (el) {
            this.fieldSortables.push(Sortable.create(el, {
              group: 'fieldSet',
              onEnd: this.onDragEndForField,
              onStart: this.onDragStartForField,
              ghostClass: 'field-ghost',
              chosenClass: 'field-chosen',
              dragClass: 'field-drag',
              handle: '.field-drag-handle',
            }))
          }
        })
    })
  },

  createFieldSelectorSortables () {
    const el = document.getElementById('item-field-selector-inner')
    this.fieldSelectorSortables = []
    if (el) {
      this.fieldSelectorSortables.push(Sortable.create(el, {
        group: {
          name: 'fieldSet',
          pull: 'clone',
        },
        onStart: this.onDragStartForField,
        onEnd: this.onDragEndForNewField,
        chosenClass: 'field-chosen',
        dragClass: 'field-drag',
      }))
    }
  },

  onDragEndForContainer () {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$nextTick(() => {
      this.changeContainersSortOrder()
      this.updateContainersInDOMAndCreateSortings()
    })
  },

  onDragEndForFieldSet (dropInfo) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$nextTick(() => {
      this.changeFieldSetsSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
    })
  },

  onDragEndForField (dropInfo) {
    this.$nextTick(() => {
      this.moveFieldAndUpdateSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
      this.draggingItemType = null
    })
  },

  onDragEndForNewField (dropInfo) {
    this.$nextTick(() => {
      this.moveFieldAndUpdateSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
      this.draggingItemType = null
    })
  },

  onDragStartForField () {
    // So we can change css for potential drop areas
    this.draggingItemType = 'form-field'
  },

  updateContainersInDOMAndCreateSortings () {
    this.sortContainersAndFieldSets()
    this.reRender()
    this.$nextTick(() => {
      this.createSortables()
    })
  },

  dragEnd () {
    // this.changeContainersSortOrder()
  },

  changeContainersSortOrder (forced = false) {
    let sortingChanged = false
    const newOrderArr = []
    let prevIndex = -1
    document.querySelectorAll('.container-form').forEach(el => {
      const index = parseInt(el.dataset.index)
      if (index < prevIndex) {
        sortingChanged = true
      }
      newOrderArr.push(index)
      prevIndex = index
    })
    if (sortingChanged || forced) {
      newOrderArr.forEach((containerIndex, newOrder) => {
        const containers = this.layoutContainers[this.selectedItemLayoutProfileId]
        if (containers[containerIndex] && 'sort_order' in containers[containerIndex]) {
          Vue.set(containers[containerIndex], 'sort_order', newOrder)
        }
      })
      this.$store.dispatch('saveLayoutContainersOrder')
    }
  },

  changeFieldSetsSortOrder (dropInfo) {
    const toContainerId = dropInfo.to.parentNode.parentNode.dataset.containerId
    const fromContainerId = dropInfo.from.parentNode.parentNode.dataset.containerId
    const fieldSets = this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]
    const newOrderArr = []
    document.querySelectorAll('.field-set-form').forEach(el => {
      newOrderArr.push(el.dataset.fieldSetId)
    })
    // Update field sets sort order in store
    newOrderArr.forEach((fieldSetId, newOrder) => {
      const fieldSet = fieldSets.find(fieldSet => fieldSet.id === parseInt(fieldSetId))
      if (fieldSet && 'sort_order' in fieldSet) {
        Vue.set(fieldSet, 'sort_order', newOrder)
      }
    })
    // Update field sets in store
    if (toContainerId !== fromContainerId) {
      const fieldSet = fieldSets.find(fieldSet => {
        return fieldSet.id === parseInt(dropInfo.item.dataset.fieldSetId)
      })
      if (fieldSet) {
        Vue.set(fieldSet, 'layout_container', {
          id: parseInt(toContainerId)
        })
      }
    }
    // Update sort order over API
    this.$store.dispatch('saveLayoutContainerFieldSetsOrder').then(() => {
      if (toContainerId !== fromContainerId) {
        // Update field sets over API
        this.$store.dispatch('updateLayoutContainerFieldSetContainer', {
          id: dropInfo.item.dataset.fieldSetId,
          layoutContainer: {
            id: parseInt(toContainerId)
          }
        })
      }
    })
  },

  moveFieldAndUpdateSortOrder (dropInfo) {
    const toFieldSetId = dropInfo.to.parentNode.parentNode.dataset.fieldSetId
    const fromFieldSetId = dropInfo.from.parentNode.parentNode.dataset.fieldSetId
    const fields = this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
    const newOrder = []
    document.querySelectorAll('.field-set-field').forEach(el => {
      if (!el) { return }
      const isInSelectorList = el.parentNode.id === 'item-field-selector-inner'
      if (isInSelectorList) { return }
      // If field was already in field set
      // include field added from selector
      // and not to include replaced field into new order array
      const wasAlreadyInFieldSet = el.dataset.inFieldSet
      const isFieldFromSelector = el.className.includes('form-field-selectable')
      const isReplacedFieldFromSelector = !fromFieldSetId && wasAlreadyInFieldSet && el.dataset.fieldId === dropInfo.item.dataset.fieldId
      if (isFieldFromSelector || (wasAlreadyInFieldSet && !isReplacedFieldFromSelector)) {
        if (!el.dataset.isHasManyField) {
          newOrder.push(el.dataset.fieldIndex)
        }
      }
    })
    // Update fields sort order in store
    newOrder.forEach((fieldIndex, newOrder) => {
      if (fields[fieldIndex] && 'sort_order' in fields[fieldIndex]) {
        Vue.set(fields[fieldIndex], 'sort_order', newOrder)
      } else {
        console.error('Can not set sort_order for a field', fields?.[fieldIndex])
        console.error(fields?.[fieldIndex]?.name)
      }
    })
    // Update field field-set in store
    if (toFieldSetId !== fromFieldSetId) {
      const field = fields[dropInfo.item.dataset.fieldIndex]
      Vue.set(field, 'layout_container_field_set', { id: parseInt(toFieldSetId) })
      Vue.set(field, 'visible', true)
    }
    // Update sort order over API
    this.$store.dispatch('saveItemFieldsOrder', { sortBy: 'sortOrderField' }).then(() => {
      if (toFieldSetId !== fromFieldSetId) {
        // Update field field-set and visibility over API
        this.$store.dispatch('updateItemLayoutProfileItem', {
          id: dropInfo.item.dataset.fieldId,
          visible: !!toFieldSetId,
          layoutContainerFieldSet: {
            id: parseInt(toFieldSetId)
          }
        })
      }
    })
  },

  itemFieldMove (movedField, toFieldSetId) {
    const fields = this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
    // Go through all fields, in case multiple were selected
    fields.filter(field => {
      // movedField can also be empty, when opened menu from the selected items total count btn
      return this.selectedLayoutEditorFields.includes(field.name) || field.name === movedField?.name
    }).forEach(field => {
      Vue.set(field, 'layout_container_field_set', { id: parseInt(toFieldSetId) })
      Vue.set(field, 'visible', true)
      // Update field field-set and visibility over API
      this.$store.dispatch('updateItemLayoutProfileItem', {
        id: field.id,
        visible: true,
        layoutContainerFieldSet: {
          id: parseInt(toFieldSetId)
        }
      })
    })
    const newOrder = []
    // Get new order from DOM (when user did drag-n-drop)
    document.querySelectorAll('.field-set-field').forEach(el => {
      if (!el) { return }
      const isInSelectorList = el.parentNode.id === 'item-field-selector-inner'
      if (isInSelectorList) { return }
      newOrder.push(el.dataset.fieldIndex)
    })
    // Update fields sort order in store
    newOrder.forEach((fieldIndex, newOrder) => {
      if (fields[fieldIndex] && 'sort_order' in fields[fieldIndex]) {
        Vue.set(fields[fieldIndex], 'sort_order', newOrder)
      }
    })
    // Update sort order over API
    this.$store.dispatch('saveItemFieldsOrder', {}).then(() => {
      this.updateContainersInDOMAndCreateSortings()
    })
    this.closeLayoutEditorMenus()
    this.selectedLayoutEditorFields = []
  },
}
