import util from '@/utilities/sharedUtilities'
import Vue from 'vue'
import clearCache from '@/utilities/clearCache'
import Confirm from '@/methods/confirm'
import { LP } from '@/types/LP.types'

const includeFieldSetFields = [
  'sort_order',
  'layout_profile',
  'layout_container',
  'name',
  'columns',
  'content_columns',
  'fieldNames',
]
const includeContainerFields = [
  'sort_order',
  'layout_profile',
  'name',
  'columns',
  'fieldSets',
]

export default {
  resetLayout () {
    Confirm.request(this.$i18n.t('aava.confirmations.destroy_objects'), () => {
      this.savingItemLayout = true
      this.resettingLayout = true
      this.deleteExistingContainersAndFieldSets().then(() => {
        this.addNewContainer().then(() => {
          this.savingItemLayout = false
          this.resettingLayout = false
        })
      })
    })
  },

  getItemLayoutExportJSON (layoutProfile, containers, fieldSets, fields) {
    const hasManyLayouts = {}
    // Add name locale fields as included export fields
    this.$store.state.availableContentLocales.forEach((locale) => {
      includeFieldSetFields.push('name_' + locale)
      includeContainerFields.push('name_' + locale)
    })
    const containersWithFieldSets: LP.Container[] = JSON.parse(
      JSON.stringify(containers),
    ).map((container) => {
      container.fieldSets = JSON.parse(JSON.stringify(fieldSets)).filter(
        (fieldSet) => {
          // Get fields inside a field set
          fieldSet.fieldNames = fields
            .filter(
              (field) => field.layout_container_field_set?.id === fieldSet.id,
            )
            .map((field) => field.name)
          // Get has-many columns and custom widths
          fields
            .filter(
              (field) =>
                field.type === 'has_many_reference' && !!field.has_many_layout,
            )
            .forEach((field) => {
              hasManyLayouts[field.name] = field.has_many_layout
            })
          Object.keys(fieldSet).forEach((key) => {
            if (!includeFieldSetFields.includes(key)) {
              delete fieldSet[key]
            }
          })
          return fieldSet.layout_container?.id === container.id
        },
      )
      Object.keys(container).forEach((key) => {
        if (!includeContainerFields.includes(key)) {
          delete container[key]
        }
      })
      return container
    })
    return JSON.stringify({
      layoutProfile: {
        name: layoutProfile.name,
      },
      containersWithFieldSets,
      hasManyLayouts,
    })
  },

  importLayoutFromJSON (json) {
    this.createContainersAndFieldSets(json).then(() => {
      this.createSortables()
    })
  },

  toggleFieldSelector () {
    this.hideFieldSelector = !this.hideFieldSelector
    if (!this.hideFieldSelector) {
      this.$nextTick(() => {
        this.createSortables()
      })
    }
  },

  sortContainersAndFieldSets () {
    // Sort containers
    this.containers.sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
    // Sort field sets - can no longer sort as all field sets are provided to the template trough .filter()
    // this.fieldSets.sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
  },

  showItemFieldMenu (e, field) {
    this.highlightedFieldName = ''
    // With Meta key click toggle selection and do not open the menu
    if (e.metaKey) {
      this.toggleLayoutEditorSelectedField(field.name)
      return
    }
    this.itemFieldForEdit = field
    this.closeLayoutEditorMenus()
    this.dialogItemFieldMenu = true
    this.itemFieldMoveCallback = this.itemFieldMove
    // Duplicate so that does not mutate store
    this.itemFieldMenuData = {
      containers: this.containers,
      fieldSets: this.fieldSets,
      field,
      fields: this.layoutProfileItemsById[this.selectedItemLayoutProfileId],
    }
    this.$nextTick(() => {
      util.positionPopupMenu(e, 'item-field-menu', 30, 20, {
        addOffset: true,
      })
    })
  },

  showItemContainerMenu ({ e, container }) {
    this.itemContainerForEdit = container
    this.closeLayoutEditorMenus()
    this.dialogItemContainerMenu = true
    this.addNewContainerCallback = this.addNewContainer
    this.addNewFieldSetCallback = this.addNewFieldSet
    this.$nextTick(() => {
      util.positionPopupMenu(e, 'item-container-menu', 0, 30, {
        addOffset: true,
      })
    })
  },

  closeLayoutEditorMenus () {
    this.dialogItemFieldSetMenu = false
    this.dialogItemContainerMenu = false
    this.dialogItemFieldMenu = false
  },

  showItemFieldsetMenu ({ e, fieldSet }) {
    this.closeLayoutEditorMenus()
    this.itemFieldSetForEdit = fieldSet
    this.dialogItemFieldSetMenu = true
    this.$nextTick(() => {
      util.positionPopupMenu(e, 'item-field-set-menu', 0, 30, {
        addOffset: true,
      })
    })
  },

  toggleLayoutEditorSelectedField (fieldName) {
    if (this.selectedLayoutEditorFields.includes(fieldName)) {
      this.selectedLayoutEditorFields.splice(this.selectedLayoutEditorFields.indexOf(fieldName), 1)
    } else {
      this.$set(this.selectedLayoutEditorFields, this.selectedLayoutEditorFields.length, fieldName)
    }
    this.closeLayoutEditorMenus()
  },

  clearSelection () {
    this.selectedLayoutEditorFields = []
    this.closeLayoutEditorMenus()
  },

  itemFieldDelete (deletedField) {
    // Go through all fields, in case multiple were selected
    this.itemFieldMenuData.fields.filter(field => {
      return this.selectedLayoutEditorFields.includes(field.name) ||
        field.name === deletedField?.name
    }).forEach(field => {
      Vue.set(field, 'visible', false)
      this.$store.dispatch('updateItemLayoutProfileItem', field)
    })
    this.closeLayoutEditorMenus()
    this.selectedLayoutEditorFields = []
    const selectedItemLayoutProfileId = this.selectedLayoutProfileIdByModel[this.objectClass]
    clearCache('lp_data_' + selectedItemLayoutProfileId)
  },
}
