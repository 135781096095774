<template>
  <div
    v-if="dialogItemFieldSetMenu"
    id="item-field-set-menu"
    class="item-field-set-menu popup-menu elevation-5"
  >
    <div
      class="popup-menu-el"
      @click.stop="deleteLayoutContainerFieldSet(itemFieldSetForEdit)"
    >
      <a href="javascript:;">
        <span class="fa fa-trash" />
        {{ $i18n.t('aava.actions.remove') }}
      </a>
    </div>
  </div>
</template>

<script>
import methods from './../../components/methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemFieldSetMenu',

  data () {
    return {
      saving: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    clickListener (e) {
      if (this.dialogItemFieldSetMenu) {
        const el = document.getElementById('item-field-set-menu')
        if (el && !el.contains(e.target)) {
          this.dialogItemFieldSetMenu = false
        }
      }
    },
  }
}
</script>
