export default {
  dialogItemContainerMenu: false,
  dialogItemFieldSetMenu: false,
  dialogItemFieldMenu: false,

  itemContainerForEdit: {},
  itemFieldSetForEdit: {},
  itemFieldForEdit: {},

  itemFieldMoveCallback: () => {},
  itemFieldMenuData: {},

  addNewContainerCallback: () => {},
  addNewFieldSetCallback: () => {},

  selectedLayoutEditorFields: [],
}
