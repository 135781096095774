
import MenuToolbar from './components/App/MenuToolbar.vue'
import AavaLoader from './components/AavaLoader.vue'
import AddressModal from './components/AddressModal.vue'
import ItemPicker from './components/ItemPicker/ItemPicker.vue'
import ItemContainerMenu from './models/LayoutContainer/LC.menu.vue'
import ItemFieldSetMenu from './models/LayoutContainerFieldSet/LCFS.menu.vue'
import ItemFieldMenu from './models/LayoutProfileItem/LPI.item.menu.vue'

import { createHelpers } from 'vuex-map-fields'
import state from './store/state'
import LoginDialog from '@/components/LoginDialog.vue'
import DateTimePickerMenu from '@/components/Form/DateTimePickerMenu.vue'
import FlashMessages from '@/components/App/FlashMessages.vue'
import MenuNavigationDrawer from '@/components/App/MenuNavigationDrawer.vue'
import ShortcutsList from '@/components/Shortcuts/ShortcutsList.vue'
import UserMenuDrawer from '@/components/App/UserMenuDrawer.vue'
import ReleaseInfo from '@/components/App/ReleaseInfo.vue'
import RestartMessage from './components/App/RestartMessage.vue'
import appMethods from '@/methods/list/appMethods'
import infoChannelMethods from '@/methods/list/infoChannelMethods'
import UserLevelSettings from './components/App/UserLevelSettings.vue'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'App',

  components: {
    UserLevelSettings,
    ReleaseInfo,
    UserMenuDrawer,
    ShortcutsList,
    MenuNavigationDrawer,
    RestartMessage,
    FlashMessages,
    DateTimePickerMenu,
    LoginDialog,
    MenuToolbar,
    AavaLoader,
    ItemPicker,
    AddressModal,
    ItemContainerMenu,
    ItemFieldSetMenu,
    ItemFieldMenu,
  },

  data () {
    return {
      resizeTimer: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    appStyle () {
      return {
        // To fix mobile height, set height for the app container
        // Otherwise height is more and causes item bottom toolbar to be not fixed
        height: (this.innerHeight) + 'px !important',
      }
    },

    routeString () {
      return JSON.stringify(this.$route.params)
    },
  },

  watch: {
    routeString () {
      this.splitModeFile = {}
    },
  },

  created () {
    this.getSessionAndAppSupportingData().then(() => {
      this.startCableConnection()
    })
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('serviceWorkerUpdated', this.refreshAppForNewVersion, { once: true })
    window.addEventListener('serviceWorkerUpdateFound', this.showAppNewVersionMessage, { once: true })
  },

  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    ...appMethods,
    ...infoChannelMethods,
  },
}
